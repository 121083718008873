import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RestaurantCard from "../../components/RestaurantCard";

import BattleOther from "../../components/BattleOther.js";

const allRecommendations = require("../recommendations.json");
const allTacoBattles = require("../battles.json");

const tacoBattlesInfo = allTacoBattles[0];

const infoForTacoA = allRecommendations.filter(function (el) {
  return el.title == tacoBattlesInfo["tacoA"]["restaurant"];
})[0];

const infoForTacoB = allRecommendations.filter(function (el) {
  return el.title == tacoBattlesInfo["tacoB"]["restaurant"];
})[0];

const TacoAVersusTacoB = () => {
  return (
    <>
      <Head
        title={`Tacos Battles: ${tacoBattlesInfo.title}`}
        description={`Austin Tacos Battles present ${tacoBattlesInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar taco-battles-node">
            <div className="primary-wrapper">
              <h1>{`Tacos Battles: ${tacoBattlesInfo.title}`}</h1>

              <div className="battle-groups-the-wrapper">
                {/* -  */}
                <div className="battle-groups">
                  <h4>{`${tacoBattlesInfo.tacoA.nameOfTaco}`}</h4>
                  <div className="img">
                    <img src={tacoBattlesInfo.tacoA.img} />
                  </div>
                  <div className="ingredients">
                    <strong>Ingredients: </strong>
                    {tacoBattlesInfo.tacoA.desc}
                  </div>
                  <div className="restaurant">
                    <strong>Restaurant: </strong>
                    <a href={infoForTacoA.link}>
                      {tacoBattlesInfo.tacoA.restaurant}
                    </a>
                  </div>
                </div>
                {/* -  */}
                <div className="battle-groups">
                  <h4>{`${tacoBattlesInfo.tacoB.nameOfTaco}`}</h4>
                  <div className="img">
                    <img src={tacoBattlesInfo.tacoB.img} />
                  </div>
                  <div className="ingredients">
                    <strong>Ingredients: </strong>
                    {tacoBattlesInfo.tacoB.desc}
                  </div>
                  <div className="restaurant">
                    <strong>Restaurant: </strong>
                    <a href={infoForTacoB.link}>
                      {tacoBattlesInfo.tacoB.restaurant}
                    </a>
                  </div>
                </div>
              </div>

              {/* + */}
              <h2>How the Tacos Compare</h2>
              <p>
                We first tried the "Fish n' chips" taco with the expectation of
                some flavors being more understated than the others, but this
                was not the case. The cod was a perfect match for the curry
                mayo, instead of either competiting against each other. The
                texture of the cod was also a perfect accent with the micro
                greens, honestly a home run.
              </p>

              <p>
                Later that night, we tried first tried the "Grilled Fish Taco"
                with the previous bar being set high. We were delighted with our
                choice, and especially how these flavors contrast. I had never
                tried this combination of tilapia and mango pico, omg. The
                avocado was a nice accent, but was not the main star of the
                flavor show.
              </p>

              {/* + */}
              <h2>
                The Winner is the {tacoBattlesInfo.tacoB.nameOfTaco} from{" "}
                {tacoBattlesInfo.tacoB.restaurant}
              </h2>
              <p>
                Getting the perfect fish taco is not an easy task. Many times it
                feels like the taco is just intended for a strong fish taste,
                with the other incrediants just there for display only. This
                wasn't the case with either of these. Both tacos combined
                incrediants to pushed the flavors to the next level. We gave
                only a slight edge to the grilled fish taco, because the flavors
                were just so oustanding, especially in how they introduced
                flavors that were were not expecting. A fish taco with cod is
                great, but tilapia seemed a better choice for how the blend
                worked together.
              </p>

              <h3>Judge for yourself and let us know!</h3>
              <div className="taco-the-battle-cards">
                <div className="taco-a-left">
                  <RestaurantCard data={infoForTacoA}></RestaurantCard>
                </div>
                <div className="taco-b-right">
                  <RestaurantCard data={infoForTacoB}></RestaurantCard>
                </div>
              </div>
            </div>

            <div className="right-sidebar">
              <BattleOther title={tacoBattlesInfo.title}></BattleOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TacoAVersusTacoB;
